<template>
  <div class="container">
    <van-nav-bar
      title="个人信息"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <div class="kbox-gray5"></div>
    <van-cell-group :border="false">
      <van-field
        center
        clearable
        label="昵称"
        v-model="userName"
        placeholder="请输入昵称   "
        input-align="right"
      >
      </van-field>
      <van-field
        input-align="right"
        name="uploader"
        :max-count="1"
        label="头像"
      >
        <template #input>
          <van-uploader
            v-model="uploader"
            :max-count="1"
            preview-size="60px"
            :after-read="afterRead"
          />
        </template>
      </van-field>
      <van-field
        input-align="right"
        label-class="label-class"
        label="地址"
        v-model="staff_add"
        is-link
        :readonly="true"
        @click="showPAreaPopup"
      ></van-field>
      <van-field
        center
        clearable
        label="籍贯"
        v-model="staff_place"
        placeholder="请输入籍贯   "
        input-align="right"
      >
      </van-field>

      <van-field
        center
        clearable
        label="职业"
        v-model="staff_job"
        placeholder="请输入职业   "
        input-align="right"
      >
      </van-field>

      <van-field
        center
        clearable
        label="公司"
        v-model="staff_company"
        placeholder="请输入公司名称   "
        input-align="right"
      >
      </van-field>
      <van-field
        center
        clearable
        label="学历"
        v-model="staff_study"
        placeholder="请输入学历状况   "
        input-align="right"
      >
      </van-field>

      <van-field
        center
        clearable
        label="工作时间"
        v-model="staff_worktime"
        placeholder="请输入工作时间   "
        input-align="right"
      >
      </van-field>

      <van-field
        center
        clearable
        label="购车情况"
        v-model="staff_iscar"
        placeholder="是否购车   "
        input-align="right"
      >
      </van-field>
      <van-field
        center
        clearable
        label="签名"
        v-model="staff_remarkes"
        placeholder="写点什么吧   "
        input-align="right"
      >
      </van-field>
    </van-cell-group>
    <!-- 地址弹出框--->
    <van-popup v-model="showPArea" position="bottom">
      <van-area
        title="选择地址"
        :value="code"
        :area-list="areaList"
        visible-item-count="4"
        @confirm="ensurePArea"
        @cancel="closePAreaPopup"
      />
    </van-popup>
    <div class="action-button">
      <van-button @click="submit" color="#F3C301" block type="primary" round
        >提 交</van-button
      >
    </div>
  </div>
</template>

<style>
.van-image__img {
  border-radius: 50% !important;
}
.container {
  background-color: #ffffff;
}

.cellTitle {
  color: #b3b3b3;
  font-size: 12px;
}
.action-button {
  margin: 20px 20px;
}
</style>

<script>
import UserService from "@/api/user";
import BaseService from "@/api/base";
import AreaList from "@/utils/area";
export default {
  data() {
    return {
      userName: "",
      uploader: [],
      headimg: null,
      showPArea: false,
      staff_add: "",
      staff_place: '',
      staff_job: '',
      staff_company: '',
      staff_study: '',
      staff_worktime: '',
      staff_iscar: '',
      staff_remarkes: '',
      areaList: AreaList,
      code: "500112",
      addressList: [], // 区域
    };
  },
  async beforeMount() {
    //得到用户统计信息
    var token = this.$cookie.get("token");
    //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
    var params = {
      token: token,
    };
    const resp1 = await UserService.staffInfo(this, params);
    if (resp1.data.code == 10000) {
      this.uploader = [
        {
          url: resp1.data.data.staff_img,
          isImage: true,
        },
      ];
      this.userName = resp1.data.data.staff_name;
      this.staff_add = resp1.data.data.staff_add;
      this.headimg = resp1.data.data.staff_img;
      this.staff_job = resp1.data.data.staff_job;
      this.staff_worktime = resp1.data.data.staff_worktime;
      this.staff_remarkes = resp1.data.data.staff_remarkes;
      this.staff_iscar = resp1.data.data.staff_iscar;
      this.staff_company = resp1.data.data.staff_company;
      this.staff_study = resp1.data.data.staff_study;
      this.staff_place = resp1.data.data.staff_place;
    }
  },
  methods: {
    async submit() {
      //更新用户信息
      // 设置头像
      
      var paramsInfo = {
        token: this.$cookie.get("token"),
        //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
        nickname: this.userName,
        headimg: this.headimg,
        staff_place: this.staff_place,
        staff_job: this.staff_job,
        staff_worktime: this.staff_worktime,
        staff_remarkes: this.staff_remarkes,
        staff_iscar: this.staff_iscar,
        staff_address: this.staff_add,
        staff_company: this.staff_company,
        staff_study: this.staff_study,
        orderRound: ''
      };
      console.log(paramsInfo);
      const resp11 = await UserService.upStaffHeadimg(this, paramsInfo);
      if (resp11.data.code == 10000) {
        this.$router.go(-1);
      }
      console.log(resp11);
    },
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    showPAreaPopup: function() {
      this.showPArea = true;
    },
    closePAreaPopup: function() {
      this.showPArea = false;
    },
    ensurePArea: function(val) {
      var data = val;
      var area = "";
      data.forEach((e) => {
        area = area + e.name;
      });
      this.staff_add = area;

      this.closePAreaPopup();
    },
    async afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        console.log(resp.data.data.imgUrl);
        this.headimg = "https://jsagency.cn/" + resp.data.data.imgUrl;
        file.status = "done";
      }
    },
  },
};
</script>
